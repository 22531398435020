import Head from "next/head";
import axios from "axios";
import { parse } from "node-html-parser";

export default function Home({ headContents, bodyContents }: any) {
  // Parse headContents to extract specific elements
  const headRoot = parse(headContents);
  const title = headRoot.querySelector("title")?.textContent;
  const metas = headRoot.querySelectorAll("meta");

  return (
    <>
      <Head>
        {title && <title>{title}</title>}
        {metas?.map((meta: any, index) => {
          const attributesArray = Object.entries(meta.attributes).map(
            ([key, value]: [string, any]) => [key, value]
          );
          return <meta key={index} {...Object.fromEntries(attributesArray)} />;
        })}
        <link rel="icon" href="/favicon.ico" />
      </Head>
      <div dangerouslySetInnerHTML={{ __html: bodyContents }} />
    </>
  );
}

export const getServerSideProps = async (context: any) => {
  try {
    const res = await axios.get(
      `https://dopweb-repository.s3.us-west-2.amazonaws.com/qrmenu/index.html`
    );
    let htmlContent = res.data;

    // Replace instances with single quotes
    htmlContent = htmlContent.replace(
      /\/mydop-files\//g,
      `https://dopweb-repository.s3.us-west-2.amazonaws.com/qrmenu/mydop-files/`
    );
    const root = parse(htmlContent);

    // Extracting <head> contents
    const headContents = root?.querySelector("head")?.toString();
    // Extracting the rest of the body contents
    const bodyContents = htmlContent;

    return { props: { headContents, bodyContents } };
  } catch (error) {
    console.error("Error fetching external HTML content:", error);
    return {
      props: { headContents: "", bodyContents: "<p>Error loading content</p>" },
    };
  }
};
